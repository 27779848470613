/**
 * 表示类型的json,转为下拉框可用的array
 * @param typeJson 数据类型的json,如companyType,userType
 */
export function typeToArray(typeJson) {
  let typeArr = [];
  for (let key in typeJson) {
    let keyInt = parseInt(key);
    let item = {
      value: keyInt,
      label: typeJson[key],
    };
    typeArr.push(item);
  }
  return typeArr;
}

export let companyType = {
  1: "制造企业",
  2: "维保企业",
  3: "安装企业",
  4: "配件企业",
  5: "物业公司",
  6: "改造单位",
  7: "其它",
  8: "使用单位",
  9: "代理商"
};

export let companyType_en = {
  1: "Manufacture Company",
  2: "Maintenance Company",
  3: "Installation Company",
  4: "Parts Company",
  5: "Property Company",
  6: "Renovation Company",
  7: "Other",
  8: "UseUnit",
  9: "Agent"
};
export let stopStatus = {
  0: "不停",
  1: "停靠",
};
export let stopStatus_en = {
  0: "Not Stop",
  1: "Stop",
};
export let codeOption = {
  1000: "人数",
  1001: "电动车",
  1002: "装修手推车",
  1003: "物品堆积",
  1004: "轿内着火",
  1005: "扒门动作",
  1006: "挡门动作",
  1007: "蹦跳动作",
  1008: "人员拥挤",
  1009: "检测有人",
  1010: "检测到烟雾",
  1011: "煤气钢化瓶",
  1012: "撞击轿壁",
  1013: "晕倒",
};

export let collectFrequency = {
  48: "250 HZ",
  49: "500 HZ",
};

export let codeOptions_en = {
  1000: "peopleNumber",
  1001: "electricCar",
  1002: "trolleyTool",
  1003: "stacking",
  1004: "burning",
  1005: "pushDoor",
  1006: "blockDoor",
  1007: "bounce",
  1008: "crowd",
  1009: "havePeople",
  1010: "smoke",
  1011: "gasHolder",
  1012: "crash",
  1013: "faint",
};

export let useUnitType = {
  1: "办公楼",
  2: "宾馆",
  3: "餐饮场所",
  4: "车站",
  5: "儿童活动中心",
  6: "公共浴池",
  7: "公园",
  8: "工厂",
  9: "机场",
  10: "家庭",
  11: "客运码头",
  12: "其它",
  13: "商场",
  14: "体育场馆",
  15: "图书馆",
  16: "写字楼",
  17: "学校",
  18: "养老机构",
  19: "医疗机构",
  20: "影剧院",
  21: "幼儿园",
  22: "展览馆",
  23: "住宅",
};

export let regular = {
  nonNegativeInteger: /^\\d+$"/, // 非负整数(正整数+0)
  phone: /^1[0-9]{10}$/, // 手机号,1开头的11位数字
  username: /^[a-zA-Z0-9_@.]{4,25}$/, // 用户名,4~25位字符，只能包含英文字母、数字、@、点、下划线
  parts_length: /^[0-9a-zA-Z]{34}$/, //长度为34的字符
};
