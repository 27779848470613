<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('elevatorNetApply.editElevatorNetApply','审核入网申请')"
    width="1300px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('elevatorNetApply.labelWidth','120px')"
      :model="model">
      <div>
        <el-divider content-position="left">{{$l("elevatorNetApply.companyInfo", "企业信息")}}</el-divider>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.useUnit')" prop="useUnit" :rules="$rule.notNull">
            <el-input v-model="model.useUnit" :placeholder="$t('common.pleaseSelect')">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectUseUnit.open(model.useUnit)"></el-button>
            </el-input>
            <use-unit-select ref="selectUseUnit" @select="onSelectUseUnit"></use-unit-select>
          </el-form-item>
          <el-form-item
            :label="$l('elevatorNetApply.useUnitDistrict','使用单位地区')"
            prop="useUnitDistrictId">
            <vm-district-select
              v-model="model.useUnitDistrictId"
              :placeholder="$t('common.pleaseSelect')">
            </vm-district-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$l('elevatorNetApply.useUnitAddress','使用单位详细地址')" prop="useUnitAddress">
            <el-input v-model="model.useUnitAddress" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.manufactured')" prop="createComName" :rules="$rule.notNull">
            <el-input v-model="model.createComName" :placeholder="$t('common.pleaseSelect')">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectCreateCom.open(null,null,model.createComName)"></el-button>
            </el-input>
            <company-select ref="selectCreateCom" @select="onSelectCreateCom"></company-select>
          </el-form-item>
          <el-form-item :label="$l('elevatorNetApply.createCreditCode','制造企业信用代码')" prop="createCreditCode">
            <el-input
              v-model.trim="model.createCreditCode"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.maintenanced')" prop="maintComName" :rules="$rule.notNull">
            <el-input v-model="model.maintComName" :placeholder="$t('common.pleaseSelect')">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectMaintCom.open(null,null,model.maintComName)"></el-button>
            </el-input>
            <company-select ref="selectMaintCom" @select="onSelectMaintCom"></company-select>
          </el-form-item>
          <el-form-item :label="$l('elevatorNetApply.maintCreditCode','维保企业信用代码')" prop="maintCreditCode">
            <el-input
              v-model.trim="model.maintCreditCode"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.maintEmpName')" prop="maintEmpName">
            <el-input v-model.trim="model.maintEmpName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.maintEmpPhone')" prop="maintEmpPhone" :rules="$rule.mobile">
            <el-input
              v-model.trim="model.maintEmpPhone"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.propertyCom')" prop="propertyCom" :rules="$rule.notNull">
            <el-input
              v-model="model.propertyCom"
              :placeholder="$t('common.pleaseSelect')">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectPropertyCom.open(null,null,model.propertyCom)"></el-button>
            </el-input>
            <company-select ref="selectPropertyCom" @select="onSelectPropertyCom"></company-select>
          </el-form-item>
          <div></div>
        </div>
      </div>
      <div>
        <div style="width: 68%;float: left;padding-right: 2%">
          <el-divider content-position="left">{{$t("elevatorNetApply.elevatorInfo")}}</el-divider>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.elevatorName')" prop="elevatorName" :rules="$rule.notNull">
              <el-input v-model="model.elevatorName" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.dtuCode')" prop="dtuCode" :rules="$rule.notNull">
              <el-input v-model="model.dtuCode" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.factoryNumber')" prop="factoryNumber">
              <el-input
                v-model="model.factoryNumber"
                :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.dtuModel')" prop="dtuModel">
              <el-input v-model="model.dtuModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.nodeCode')" prop="nodeCode">
              <el-input v-model="model.nodeCode" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.regCode')" prop="regCode">
              <el-input v-model.trim="model.regCode" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.sim')" prop="sim">
              <el-input v-model.trim="model.sim" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.elevatorModel')" prop="elevatorModel">
              <el-input v-model="model.elevatorModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.controlModel')" prop="controlModel">
              <el-input v-model="model.controlModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.elevatorType')" prop="elevatorProductTypeId">
              <el-select
                v-model="model.elevatorProductTypeId"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%">
                <el-option
                  v-for="item in productTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.speed')" prop="speed" :rules="$rule.notNull">
              <el-input v-model.trim="model.speed" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.ladderNo')" prop="ladderNo">
              <el-input v-model="model.ladderNo" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.brand')" prop="brand">
              <el-input v-model.trim="model.brand" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.buildingNo')" prop="buildingNo">
              <el-input v-model.trim="model.buildingNo" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.load')" prop="load">
              <el-input v-model="model.load" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.exFactoryDate')" prop="factoryDate">
              <el-date-picker
                v-model="model.factoryDate"
                type="date"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.installationDate')" prop="installDate">
              <el-date-picker
                v-model="model.installDate"
                type="date"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.videoChannelId')" prop="videoChannelId">
              <el-input v-model="model.videoChannelId" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
        </div>
        <div style="width: 30%;float: left;">
          <el-divider content-position="left">楼层对照</el-divider>
          <el-button type="primary" style="margin-bottom: 10px" @click="$refs.floorSelect.open()">选择楼层模板</el-button>
          <floor-display-select ref="floorSelect" @select="onSelectFloor"></floor-display-select>
          <el-table
            ref="vmTable"
            height="360px"
            border
            :data="model.floorMappings">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="floorLocation"
              :label="$l('elevatorNetApply.floorLocation','楼层位置')"
              align="center"
              width="120">
              <template slot-scope="scope">
                <el-input v-model="scope.row.floorLocation"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="code" :label="$l('elevatorNetApply.displayCode','显示代码')" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.code"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :label="$l('elevatorNetApply.operate','操作')"
              align="center"
              width="100px">
              <template slot-scope="scope">
                <el-button type="danger" @click="model.floorMappings.splice(scope.$index, 1);">
                  {{$l("elevatorNetApply.delete", "删除")}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-bottom: 10px">
            <el-button type="text" @click="addRow">
              + {{$l("elevatorNetApply.addFloorMappings", "请添加楼层信息")}}
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button type="success" :loading="createLoading" @click="create">
        {{$l("elevatorNetApply.generated", "生成")}}
      </el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrictSelect from "../../components/VmDistrictSelect";
  import UseUnitSelect from "@/views/company/useUnitCom/UseUnitSelect";
  import CompanySelect from "@/views/company/CompanySelect";
  import FloorDisplaySelect from "@/views/floorDisplay/FloorDisplaySelect";

  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: {VmDistrictSelect, UseUnitSelect, CompanySelect, FloorDisplaySelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        createLoading: false,
        activeTab: "first",
        useUnitList: [],
        companyList: [],
        productTypeList: [],
        model: {
          id: 0,
          useUnit: "",
          useUnitAddress: "",
          createComName: "",
          createCreditCode: "",
          maintComName: "",
          maintCreditCode: "",
          maintEmpName: "",
          maintEmpPhone: "",
          propertyCom: "",
          dtuCode: "",
          nodeCode: "",
          buildingNo: "",
          ladderNo: "",
          elevatorProductTypeId: "",
          brand: "",
          elevatorModel: "",
          load: "",
          speed: "",
          sim: "",
          dtuModel: "",
          controlModel: "",
          regCode: "",
          factoryNumber: "",
          elevatorName: "",
          factoryDate: "",
          installDate: "",
          videoChannelId: "",
          floorMappings: [],
          useUnitDistrictId: 0,
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getAll();
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("elevatorNetApply/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      addRow() {
        this.model.floorMappings.push({
          code: "",
          floorLocation: "",
        });
      },
      onSelectUseUnit(row) {
        this.model.useUnit = row.name;
      },
      onSelectMaintCom(row) {
        this.model.maintComName = row.name;
      },
      onSelectPropertyCom(row) {
        this.model.propertyCom = row.name;
      },
      onSelectCreateCom(row) {
        this.model.createComName = row.name;
      },
      onSelectFloor(row) {
        this.$http.get("floorDisplays/" + row.id).then((res) => {
          this.model.floorMappings = [];
          res.data.floorDisplayMappings.forEach(floor => {
            this.model.floorMappings.push({
              code: floor.displayValue,
              floorLocation: floor.faultFloorCode,
            });
          });
        });
      },
      create(row) {
        this.$refs.form.validate(valid => {
          if (!this.model.floorMappings.length) {
            this.$message.error(this.$t("common.tip.completeFloorInformation"));
            return;
          }
          if (!this.model.factoryNumber && !this.model.regCode) {
            this.$message.error(this.$t("common.tip.optional"));
            return;
          }
          if (valid) {
            this.createLoading = true;
            this.$http.save("elevatorNetApply", this.model)
              .then(() => {
                this.$emit("save-success");
              }).catch(() => {
                this.$message.error("生成失败");
              });
            this.$http.post("elevatorNetApply/create/" + this.model.id).then(() => {
              this.createLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$l("elevatorNetApply.generateSuccess", "生成成功"));
            }).catch(() => {
              this.createLoading = false;
              this.$message.error("生成失败");
            });
          }
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (!this.model.floorMappings.length) {
            this.$message.error(this.$t("common.tip.completeFloorInformation"));
            return;
          }
          if (!this.model.factoryNumber && !this.model.regCode) {
            this.$message.error(this.$t("common.tip.optional"));
            return;
          }
          if (valid) {
            this.submitLoading = true;
            this.$http.save("elevatorNetApply", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
      getAll() {
        this.$axios.all([this.getProductTypeList()]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName};
            } else {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn};
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
