<template>
  <div>
    <!--    <el-alert-->
    <!--      slot="toolbar"-->
    <!--      style="margin-bottom: 0px"-->
    <!--      title="入网申请将由平台管理员审核后生成电梯档案，如需加急处理请联系平台管理员！"-->
    <!--      center-->
    <!--      :closable="false"-->
    <!--      type="error">-->
    <!--    </el-alert>-->
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" @clear="clearDateRange" url="elevatorNetApply">
      <template slot="toolbar">
        <el-button class="addBtn" v-if="platformNoAuth" @click="$refs.editPage.open(0)">
          {{ $t("common.add") }}
        </el-button>
        <!-- <el-button v-if="showCheck && false" @click="$refs.batchSetting.open()">
          {{ $l("elevatorNetApply.downloadTemplate", "批量设置") }}
        </el-button> -->
        <el-button class="greenBtn" @click="downloadTemplate()">
          {{ $t("elevatorNetApply.downloadTemplate") }}
        </el-button>

        <el-upload :action="uploadUrl" :before-upload="beforeUpload" :on-success="onUploadSuccess"
          :on-error="onUploadError" :on-exceed="onUploadExceed" :show-file-list="false" :headers="uploadHeaders"
          accept=".xlsx" :limit="1" :disabled="importLoading" style="display: inline-block;">
          <el-button v-if="platformNoAuth" :loading="importLoading" style="margin: 0 10px;">
            {{ $t("elevatorNetApply.importElevator") }}
          </el-button>
        </el-upload>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('elevatorNetApply.createSource')">
          <el-select v-model.trim="search.createType" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.gateWayType')">
          <el-select v-model="search.gateWayType" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in gateWayTypeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <!--          <vm-search :label="$l('elevatorNetApply.createSource','审核状态')">-->
        <!--            <el-select-->
        <!--              v-model.trim="search.isCheck"-->
        <!--              :placeholder="$t('common.pleaseSelect')"-->
        <!--              style="width: 100%"-->
        <!--              clearable>-->
        <!--              <el-option-->
        <!--                v-for="item in checkTypeOptions"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value"></el-option>-->
        <!--            </el-select>-->
        <!--          </vm-search>-->
        <vm-search :label="$t('dayWeekMonthManger.createTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="dtuCode" :label="$t('elevatorNetApply.dtuCode')" width="120" align="center">
        <template slot-scope="scope">
          <router-link v-if="scope.row.elevatorId" :to="'/elevator/' + scope.row.elevatorId" target="_blank">
            {{ scope.row.dtuCode }}
          </router-link>
          <div v-else>{{ scope.row.dtuCode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="factoryNumber" :label="$t('elevatorNetApply.factoryNumber')" width="140"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip :label="$t('elevatorNetApply.elevatorName')">
      </el-table-column>
      <el-table-column prop="gateWayType" :label="$t('elevator.gateWayType')" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.gateWayType === 1">
            {{ $t("elevator.gateWayType1") }}
          </span>
          <span v-if="scope.row.gateWayType === 3">
            {{ $t("elevator.gateWayType3") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="useUnit" show-overflow-tooltip :label="$t('elevatorNetApply.useUnit')"></el-table-column>
      <el-table-column prop="createComName" show-overflow-tooltip
        :label="$t('elevatorNetApply.manufacturerCompany')"></el-table-column>
      <el-table-column prop="maintComName" show-overflow-tooltip
        :label="$t('elevatorNetApply.maintenanceCompany')"></el-table-column>
      <!-- <el-table-column prop="propertyCom" show-overflow-tooltip
        :label="$l('elevatorNetApply.useUnit', '物业单位')"></el-table-column> -->
      <el-table-column prop="agentName" show-overflow-tooltip
                       :label="$t('elevator.agent')"></el-table-column>
      <el-table-column prop="userName" show-overflow-tooltip :label="$t('dayWeekMonthManger.creator')" width="100"
        align="center"></el-table-column>
      <el-table-column prop="isWeb" :label="$t('elevatorNetApply.createSource')" width="140" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.createType === 1">
            {{ $t("elevatorNetApply.webAdd") }}
          </span>
          <span v-if="scope.row.createType === 2">
            {{ $t("elevatorNetApply.webImport") }}
          </span>
          <span v-if="scope.row.createType === 3">
            {{ $t("elevatorNetApply.app") }}
          </span>
          <span v-if="scope.row.createType === 4">
            {{ $t("elevatorNetApply.apiImport") }}
          </span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="isCheck"-->
      <!--        :label="$l('elevator.joinStatus','审核状态')"-->
      <!--        width="100px"-->
      <!--        align="center">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-tag :type="scope.row.isCheck === 1 ? 'success' : 'info'">-->
      <!--            {{scope.row.isCheck === 1 ? $l("elevator.joined", "已审核") : $l("elevator.notJoined", "审核中")}}-->
      <!--          </el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="createTime" :label="$t('dayWeekMonthManger.createTime')" width="140"
        align="center"></el-table-column>
      <el-table-column :label="$t('common.operate')" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <!--          <el-button v-if="showCheck" type="success" @click="$refs.check.open(scope.row.id)">-->
          <!--            {{$l("elevatorNetApply.generate", "审核")}}-->
          <!--          </el-button>-->
          <el-button type="text" @click="edit(scope.row)">
            {{ $t("common.edit") }}
          </el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{ $t("common.delete",
          ) }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <batch-setting ref="batchSetting" @save-success="getList(-1)"></batch-setting>
    <net-apply-check ref="check" @save-success="getList(-1)"></net-apply-check>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
import EditPage from "./Edit";
import NetApplyCheck from "@/views/elevatorNetApply/NetApplyCheck";
import auth from "@/util/auth";
import BatchSetting from "@/views/elevatorNetApply/BatchSetting";

export default {
  components: { EditPage, NetApplyCheck, BatchSetting },
  data() {
    return {
      importLoading: false,
      platformNoAuth: auth.getCurrentOrgId() != 0,
      showCheck: auth.getUsername() === "superAdmin",
      uploadUrl: window.config.SERVER_URL + "elevatorNetApply/importElevator",
      uploadHeaders: { Authorization: auth.getToken() },
      loading: true,
      dateRange: [],
      search: {
        filter: "",
        gateWayType: "",
        createType: "",
        isCheck: "",
        startTime: "",
        endTime: "",
      },
      typeOptions: [
        { value: 1, label: this.$t("elevatorNetApply.webAdd") },
        { value: 2, label: this.$t("elevatorNetApply.webImport") },
        { value: 3, label: this.$t("elevatorNetApply.app") },
        { value: 4, label: this.$t("elevatorNetApply.apiImport") },
      ],
      // checkTypeOptions: [
      //   { value: 0, label: this.$t("elevatorNetApply.underReview") },
      //   { value: 1, label: this.$t("elevatorNetApply.reviewed") },
      // ],
      gateWayTypeOptions: [
        { value: 1, label: this.$t("elevator.gateWayType1") },
        { value: 3, label: this.$t("elevator.gateWayType3") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    edit(row) {
      this.$refs.editPage.open(row.id);
      // if (row.isCheck === 1) {
      //   this.$message.error("已审核不可再编辑");
      // }
      // if (row.isCheck === 0) {
      //   this.$refs.editPage.open(row.id);
      // }
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.name") + " " + row.elevatorName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("elevatorNetApply", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
    // create(row) {
    //   this.$confirm(this.$l("common.generate", "生成电梯档案") + " ( " + this.$t("elevatorNetApply.dtuCode") + " " + row.dtuCode + " )," + this.$l("common.isGenerate", "确定吗") + "?", this.$t("common.tips"), {
    //     confirmButtonText: this.$t("common.confirm"),
    //     cancelButtonText: this.$t("common.cancel"),
    //     type: "warning",
    //   }).then(() => {
    //     this.loading = true;
    //     this.$http.post(`elevatorNetApply/create/${row.id}`).then(() => {
    //       this.loading = false;
    //       this.getList(1);
    //       this.$message.success(this.$l("elevatorNetApply.generateSuccess", "生成成功"));
    //     }).catch(() => {
    //       this.loading = false;
    //     });
    //   });
    // },
    downloadTemplate() {
      if (this.$i18n.isCn) {
        window.open("/file/入网模板2024.xlsx", "_self");
      } else {
        window.open("/file/NetTemplate2024.xlsx", "_self");
      }
    },
    beforeUpload(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      let lastName = fileName.substring(index, fileName.length);
      if (lastName.toLowerCase() !== ".xlsx") {
        this.$message.error(this.$t("elevatorNetApply.uploadType"));
        return false;
      }
      this.importLoading = true;
      return true;
    },
    onUploadSuccess() {
      this.importLoading = false;
      this.$message.success(this.$t("elevatorNetApply.importSuccess"));
      this.getList(1);
    },
    onUploadError(err) {
      this.importLoading = false;
      if (!err.message) {
        this.$message.error(this.$t("elevatorNetApply.importFailure"));
      } else {
        console.log(JSON.parse(err.message).message);
        console.log(err.message);
        this.$message.error(this.$t("elevatorNetApply.importFailure") + ": " + JSON.parse(err.message).message);
      }
    },
    onUploadExceed() {
      this.$message.error(this.$t("elevatorNetApply.importOnly"));
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
