<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select') + ' ' + $t('floorDisplay.floorDisplay')"
    :visible.sync="dialogVisible"
    width="80%"
    top="5vh"
    append-to-body>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="floorDisplays">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter','关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('floorDisplay.no')"></el-table-column>
      <el-table-column prop="name" :label="$t('floorDisplay.name')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="handleSelect(scope.row)">{{$t("common.select")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        loading: false,
        search: {
          filter: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelect(row) {
        this.$emit("select", row);
        this.dialogVisible = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
